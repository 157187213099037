import { FC } from "react";
import classNames from "classnames";
import BettingTeamName from "../BettingTeamName";
import { isGoodTeam } from "./config";
import { Controller, useFormContext } from "react-hook-form";
import { Select, Option } from "@material-tailwind/react";

export type DetailProps = {
  betId: string;
  status: string;
  matches: any[];
  changeTo?: string;
};

const Detail: FC<DetailProps> = ({ betId, matches, status, changeTo }) => {
  const { control, formState } = useFormContext();
  return (
    <div className="flex flex-col gap-y-4">
      <div className="text-xs">
        Bet Id: <b className="text-blue-gray-600 italic">{betId}</b>
      </div>
      <div className="text-xs flex items-center gap-x-4">
        <div>
          Status: <b className="text-blue-gray-600 italic">{status}</b>{" "}
          {!!changeTo && (
            <>
              {`==>`} <b>{changeTo}</b>
            </>
          )}
        </div>
      </div>

      <div className="">
        <div className="flex text-xs bg-white">
          <div className="border-b py-1 font-bold border-black w-6 text-center">
            No.
          </div>
          <div className="border-b py-1 font-bold border-l border-black flex-1 text-center">
            Matches
          </div>
        </div>
        {matches?.map((match, index) => {
          let isHomeTeamGood = false;
          let isAwayTeamGood = false;

          if (match.value !== "0" || match.option === 3) {
            if (match.option === 0 && isGoodTeam(match.value)) {
              isHomeTeamGood = true;
            }

            if (match.option === 0 && !isGoodTeam(match.value)) {
              isAwayTeamGood = true;
            }

            if (match.option === 1 && isGoodTeam(match.value)) {
              isAwayTeamGood = true;
            }

            if (match.option === 1 && !isGoodTeam(match.value)) {
              isHomeTeamGood = true;
            }
          }
          return (
            <div key={match._id} className="odd:bg-blue-gray-100 even:bg-white">
              <div className="flex">
                <div className="">
                  <div className="bg-yellow-200 mt-2 w-6 text-center">
                    {index + 1}.
                  </div>
                </div>
                <div className="text-xs border-l p-2 border-black flex-1">
                  <div
                    className={classNames("italic font-semibold bg-blue-50", {
                      "text-red-500": isHomeTeamGood,
                    })}
                  >
                    {match.matchName.en.h}
                  </div>
                  <div
                    className={classNames("italic font-semibold bg-blue-50", {
                      "text-red-500": isAwayTeamGood,
                    })}
                  >
                    {match.matchName.en.a}
                  </div>
                  {match.table === "live" && (
                    <div>
                      Score at that time: <b>[{match.score}]</b>
                    </div>
                  )}
                  <div>
                    Type:{" "}
                    <b
                      className={classNames({
                        "text-red-500": match.table === "live",
                        "text-green-800": match.table === "today",
                      })}
                    >
                      {match.table}{" "}
                      {match.table === "live" ? `(${match.info?.lt})` : ""}
                    </b>
                  </div>
                  <div>
                    Market:{" "}
                    <b>
                      {`${match.betType}`.toUpperCase()}{" "}
                      {match.betType !== "oe" && `[${match.value}]`}
                    </b>
                  </div>
                  <div>
                    Price:{" "}
                    <b
                      className={classNames({
                        "text-red-500": match.price < 0,
                      })}
                    >
                      {match.price}
                    </b>
                  </div>
                  <div className="flex">
                    <span>Bet side: </span>
                    <BettingTeamName
                      option={match.option}
                      isHomeGoodTeam={isHomeTeamGood}
                      isAwayGoodTeam={isAwayTeamGood}
                      home={match.matchName.en.h}
                      away={match.matchName.en.a}
                      betPriceType={match.betType}
                    />
                  </div>

                  <div>
                    Result:{" "}
                    <b
                      className={classNames({
                        "text-red-500": match.status?.includes("lose"),
                        "text-green-800": match.status?.includes("win"),
                        "text-blue-800": match.status === "draw",
                      })}
                    >
                      {changeTo === "done" ? (
                        <Controller
                          control={control}
                          rules={{
                            validate: (data) => {
                              return data !== "running";
                            },
                          }}
                          name={`matches.[${index}]`}
                          defaultValue={match.status}
                          render={({ field: { onChange, ref, value } }) => (
                            <>
                              <Select
                                className="bg-white"
                                onChange={onChange}
                                ref={ref}
                                value={value}
                                error={
                                  !!(formState.errors?.matches as any)?.[index]
                                }
                              >
                                <Option value="running">Running</Option>
                                <Option value="win">Win</Option>
                                <Option value="halfWin">Half win</Option>
                                <Option value="lose">Lose</Option>
                                <Option value="halfLose">Half lose</Option>
                                <Option value="draw">Draw</Option>
                              </Select>
                              <p className="pl-2 mt-1 text-red-500">{!!(formState.errors?.matches as any)?.[index] && "Please change the result to other"}</p>
                            </>
                          )}
                        />
                      ) : (
                        match.status
                      )}

                      {match.status !== "running" &&
                        `HT [${match.result?.periodScores?.[0]?.homeScore}:${match.result?.periodScores?.[0]?.awayScore}] | FT [${match.result.homeScore}:${match.result.awayScore}]`}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Detail;
