import { FC, useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useForm, FormProvider } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import service from "../../service";
import Detail from "../../Component/TicketTable/Detail";

const Settlement: FC = () => {
  const [event, setEvent] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (!open === false) {
      setEvent("");
    }
    setOpen(!open);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const betId = searchParams.get("betId") || "";
  const form1 = useForm({
    defaultValues: {
      betId,
    },
  });

  const form2 = useForm<{ matches: string[] }>();

  const ticket = useQuery({
    queryFn: () =>
      service.getTicket({
        betId,
      }),
    queryKey: ["ticket", betId],
    enabled: form1.formState.isSubmitted && !!betId,
  });

  return (
    <>
      <div className="my-3">
        <Card className="">
          <CardBody className="flex gap-5 flex-col">
            <section>
              <form
                noValidate
                onSubmit={form1.handleSubmit((data) => {
                  setSearchParams({ betId: data.betId });
                })}
              >
                <div className="flex gap-x-4 mb-5">
                  <Input
                    label="Bet ID"
                    className="bg-white"
                    crossOrigin={undefined}
                    {...form1.register("betId")}
                  />
                  <Button color="green" type="submit">
                    Find
                  </Button>
                </div>
              </form>
              {!!ticket.data?.data?.[0] && (
                <div className="w-full">
                  <FormProvider {...form2}>
                    <Detail
                      betId={ticket.data?.data?.[0]?.betId}
                      matches={ticket.data?.data?.[0]?.sport}
                      status={ticket.data?.data?.[0]?.status}
                    />
                  </FormProvider>
                  <div className="mt-5 flex gap-x-4">
                    {ticket.data?.data?.[0]?.status === "waiting" && (
                      <>
                        <Button
                          type="button"
                          color="green"
                          onClick={() => {
                            setEvent("running");
                            handleOpen();
                          }}
                        >
                          Running
                        </Button>
                        <Button
                          type="button"
                          color="orange"
                          onClick={() => {
                            setEvent("reject");
                            handleOpen();
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                    {ticket.data?.data?.[0]?.status === "running" && (
                      <>
                        <Button
                          type="button"
                          color="blue"
                          onClick={() => {
                            setEvent("settle");
                            handleOpen();
                          }}
                        >
                          Settle
                        </Button>
                        <Button
                          type="button"
                          color="red"
                          onClick={() => {
                            setEvent("cancel");
                            handleOpen();
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                    {(ticket.data?.data?.[0]?.status === "done" ||
                      ticket.data?.data?.[0]?.status === "cancelled") && (
                      <>
                        <Button
                          type="button"
                          onClick={() => {
                            setEvent("rollback");
                            handleOpen();
                          }}
                        >
                          Rollback
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </section>
          </CardBody>
        </Card>
      </div>

      <Dialog open={open} handler={handleOpen}>
        <FormProvider {...form2}>
          <form
            onSubmit={form2.handleSubmit((data) => {
              if (event === "settle") {
                service
                  .manualStatus({
                    event,
                    betId: ticket.data.data[0].betId,
                    matches: data.matches.map((match) => ({
                      result: match,
                    })),
                  })
                  .then(() => {
                    ticket.refetch();
                    handleOpen();
                  })
                  .catch(() => {
                    window.alert("Something went wrong");
                  });
              } else {
                service
                  .manualStatus({
                    event,
                    betId: ticket.data.data[0].betId,
                  })
                  .then(() => {
                    ticket.refetch();
                    handleOpen();
                  })
                  .catch(() => {
                    window.alert("Something went wrong");
                  });
              }
            })}
          >
            <DialogHeader>
              Do you want to manual the ticket to {event}?
            </DialogHeader>
            <DialogBody>
              <Detail
                betId={ticket.data?.data?.[0]?.betId}
                matches={ticket.data?.data?.[0]?.sport}
                status={ticket.data?.data?.[0]?.status}
                changeTo={
                  event === "running"
                    ? "running"
                    : event === "cancel"
                    ? "cancal"
                    : event === "settle"
                    ? "done"
                    : event === "reject"
                    ? "reject"
                    : event === "rollback"
                    ? "running"
                    : ""
                }
              />
            </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={handleOpen}
                className="mr-1"
              >
                Cancel
              </Button>
              <Button variant="gradient" color="green" type="submit">
                Confirm
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default Settlement;
