import { Input, Button, Typography } from "@material-tailwind/react";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import service from "../../service";

export type LoginForm = {
  captcha: string;
  username: string;
  password: string;
};

const Login = () => {
  const queryClient = useQueryClient();
  const { register, handleSubmit, setError, formState, reset } =
    useForm<LoginForm>();
  const clientSignature = useQuery({
    queryKey: ["clientSignature"],
    queryFn: service.clientSignature,
  });

  const login = useMutation(service.login, {
    onSuccess: async (res) => {
      window?.localStorage.setItem("auth", res.data.data.token);
      const profile = await service.getProfile();
      queryClient.setQueryData(["profile"], profile.data);
      navigate("/");
    },
    onError: (error: AxiosError<{ code: number; msg: string }>) => {
      switch (error?.response?.data?.code) {
        case 10001:
          setError("root", { message: error?.response?.data?.msg || "" });
          break;
        case 10002:
          setError("captcha", { message: error?.response?.data?.msg || "" });
          break;
        default:
          break;
      }
      return;
    },
  });

  const navigate = useNavigate();
  return (
    <div className="flex h-full min-h-screen justify-center items-center">
      <video playsInline autoPlay muted loop>
        <source
          src={`${process.env.PUBLIC_URL}/video/football.mp4`}
          type="video/mp4"
        />
      </video>

      <section className="z-10 text-center max-w-lg rounded-lg bg-white p-8">
        <h1 className="mb-5 text-2xl font-bold">Sportbook Backoffice</h1>
        <form
          className="flex flex-col gap-y-3"
          onSubmit={handleSubmit(async (formData) => {
            reset();
            login.mutate({
              password: formData.password.trim(),
              username: formData.username.trim(),
              captcha: formData.captcha,
              clientSignature: clientSignature.data?.result.uuid || "",
            });
          })}
        >
          <Input
            className="bg-white"
            crossOrigin=""
            label="Username"
            error={Boolean(formState.errors.root)}
            {...register("username")}
          />
          <Input
            className="bg-white"
            crossOrigin=""
            label="Password"
            type="password"
            error={Boolean(formState.errors.root)}
            {...register("password")}
          />
          {Boolean(formState.errors.root) && (
            <Typography variant="small" color="red">
              {formState.errors.root?.message}
            </Typography>
          )}

          {!!clientSignature.data?.result?.captchaUrl && (
            <>
              <img
                className="mx-auto my-3"
                src={clientSignature.data?.result?.captchaUrl}
                alt=""
                width={150}
                height={50}
              />
              <Input
                className="bg-white !border-t-blue-gray-200 focus:!border-t-gray-900"
                crossOrigin=""
                placeholder="Please type the captcha text"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                containerProps={{
                  className: "min-w-0",
                }}
                {...register("captcha")}
              />
              {Boolean(formState.errors.captcha) && (
                <Typography variant="small" color="red">
                  {formState.errors.captcha?.message}
                </Typography>
              )}
            </>
          )}

          <Button type="submit" color="green">
            Log in
          </Button>
          <div>
            <p>Please contact admin by clicking this link</p>
            <p>if you have any problem.</p>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Login;
